import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import CaregiverService from 'services/CaregiverService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    caregiverData: [],
    participants:[],
    guestParticipants: [],
    singleCaregiver:[],
    singleParticipant:[],
    caregiverFinishedProgram:[],
    caregiverOngoingProgram:[],
    caregiverUpcomingProgram:[],
    participantFinishedProgram:[],
    participantOngoingProgram:[],
    participantUpcomingProgram:[],
}


export const getCaregiver = createAsyncThunk('caregiver/getCaregiver', async (params, { rejectWithValue }) => {
	
	try {
        console.log('params', params);
		const response = await CaregiverService.getCaregiver(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getCaregiverGuestParticipants = createAsyncThunk('caregiver/getCaregiverGuestParticipants', async (data, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getCaregiverGuestParticipants(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getCaregiverParticipants = createAsyncThunk('caregiver/getCaregiverParticipants', async (data, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getCaregiverParticipants(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const deleteCaregiver = createAsyncThunk('caregiver/deleteCaregiver', async (id, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.deleteCaregiver(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getCaregiverWithId = createAsyncThunk('caregiver/getCaregiverWithId', async (params, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getCaregiverWithId(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getParticipantWithId = createAsyncThunk('caregiver/getParticipantWithId', async (params, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getParticipantWithId(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getCaregiverProgram = createAsyncThunk('caregiver/getCaregiverProgram', async (params, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getCaregiverProgram(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getParticipantProgram = createAsyncThunk('caregiver/getParticipantProgram', async (params, { rejectWithValue }) => {
	
	try {
		const response = await CaregiverService.getParticipantProgram(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const caregiverSlice = createSlice({
    name: 'caregiver',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getCaregiver.pending, (state) => {
            state.loading = true
        })
        .addCase(getCaregiver.fulfilled, (state, action) => {
            state.loading = false
            state.caregiverData = action.payload
        })
        .addCase(getCaregiver.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getCaregiverParticipants.pending, (state) => {
           // state.participants = []
            state.loading = true
            
        })
        .addCase(getCaregiverParticipants.fulfilled, (state, action) => {
            state.loading = false
            state.participants = action.payload
        })
        .addCase(getCaregiverParticipants.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getCaregiverGuestParticipants.pending, (state) => {
            // state.participants = []
             state.loading = true
             
         })
         .addCase(getCaregiverGuestParticipants.fulfilled, (state, action) => {
             state.loading = false
             state.guestParticipants = action.payload
         })
         .addCase(getCaregiverGuestParticipants.rejected, (state, action) => {
             state.message = action.payload
             state.showMessage = true
             state.loading = false
         })
        .addCase(deleteCaregiver.pending, (state) => {
            state.loading = true
        })
        .addCase(deleteCaregiver.fulfilled, (state, action) => {
            state.loading = false
           // state.caregiverData = action.payload
        })
        .addCase(deleteCaregiver.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getCaregiverWithId.pending, (state) => {
            state.loading = true
        })
        .addCase(getCaregiverWithId.fulfilled, (state, action) => {
            state.loading = false
            state.singleCaregiver = action.payload
        })
        .addCase(getCaregiverWithId.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getParticipantWithId.pending, (state) => {
            state.loading = true
        })
        .addCase(getParticipantWithId.fulfilled, (state, action) => {
            state.loading = false
            state.singleParticipant = action.payload
        })
        .addCase(getParticipantWithId.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getCaregiverProgram.pending, (state) => {
            state.loading = true
        })
        .addCase(getCaregiverProgram.fulfilled, (state, action) => {
            state.loading = false
            if(action.meta.arg.filter === 'Finished'){
                state.caregiverFinishedProgram= action.payload
             }
             if(action.meta.arg.filter === 'Ongoing'){
                state.caregiverOngoingProgram= action.payload
             }
             if(action.meta.arg.filter === 'Upcoming'){
                state.caregiverUpcomingProgram= action.payload
             }
        })
        .addCase(getCaregiverProgram.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getParticipantProgram.pending, (state) => {
            state.loading = true
        })
        .addCase(getParticipantProgram.fulfilled, (state, action) => {
            state.loading = false
            if(action.meta.arg.filter === 'Finished'){
                state.participantFinishedProgram= action.payload
             }
             if(action.meta.arg.filter === 'Ongoing'){
                state.participantOngoingProgram= action.payload
             }
             if(action.meta.arg.filter === 'Upcoming'){
                state.participantUpcomingProgram= action.payload
             }
        })
        .addCase(getParticipantProgram.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default caregiverSlice.reducer;