import fetch from 'auth/FetchInterceptor';
import {API_BASE_URL} from '../configs/AppConfig';

const FaqService = {};

FaqService.addFaq = function (data) {
    return fetch({
        url: API_BASE_URL+'/v1/admin/add-update-faq',
		method: 'post',
		data: data,
    })
};

FaqService.getAllFaq = function (params) {
    let page = params
	let offset = (page-1)*10
    return fetch({
        url: API_BASE_URL+'/v1/admin/get-faq-data',
		method: 'get',
		//data: data,
        params: {
            'offset': offset
        },
    })
};

export default FaqService;

