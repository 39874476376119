import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const QuestionAnswerService = {}


QuestionAnswerService.getQuestionAnswer = function (params) {
	let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-question',
		method: 'get',
        params: {
            'offset' : offset
        }
	})
};

QuestionAnswerService.getAnswers = function (params) {
	let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-answer',
		method: 'get',
        params: {
            'offset' : offset
        }
	})
};

QuestionAnswerService.addQuestion = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-question',
		method: 'post',
        data: data
	})
};

QuestionAnswerService.addQuestionTag = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-question-tag',
		method: 'post',
        data: data
	})
};


export default QuestionAnswerService;