import fetch from 'auth/FetchInterceptor';
import {API_BASE_URL} from '../configs/AppConfig';

const TagService = {};

TagService.addTag = function (data) {
    return fetch({
        url: API_BASE_URL+'/v1/admin/add-update-tag',
		method: 'post',
		data: data,
    })
};

TagService.getAllTag = function (params) {
    let page = params
	let offset = (page-1)*10
    return fetch({
        url: API_BASE_URL+'/v1/admin/get-tag-data',
		method: 'get',
		//data: data,
        params: {
            'offset': offset
        },
    })
};

export default TagService;

