import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import ParticipantService from 'services/ParticipantService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    participantData:[],
    guestParticipantData: [],
    singleParticipant: [],
    allTag: [],
}

export const getParticipant = createAsyncThunk('participant/getParticipant', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await ParticipantService.getParticipant(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getGuestParticipant = createAsyncThunk('participant/getGuestParticipant', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await ParticipantService.getGuestParticipant(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getParticipantWithId = createAsyncThunk('participant/getParticipantWithId', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await ParticipantService.getParticipantWithId(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addParticipantTag = createAsyncThunk('participant/addParticipantTag', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await ParticipantService.addParticipantTag(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const allTags = createAsyncThunk('participant/allTags', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await ParticipantService.allTags(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});



export const participantSlice = createSlice({
    name: 'participant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getParticipant.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.participantData = action.payload
            state.message= ''
        })
        .addCase(getParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getGuestParticipant.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getGuestParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.guestParticipantData = action.payload
            state.message= ''
        })
        .addCase(getGuestParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getParticipantWithId.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getParticipantWithId.fulfilled, (state, action) => {
            state.loading = false
            state.singleParticipant = action.payload
            state.message= ''
        })
        .addCase(getParticipantWithId.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addParticipantTag.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addParticipantTag.fulfilled, (state, action) => {
            state.loading = false
            state.message = 'Tag Added'
           //   state.singleParticipant = action.payload
        })
        .addCase(addParticipantTag.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(allTags.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(allTags.fulfilled, (state, action) => {
            state.loading = false
            state.allTag = action.payload
            state.message= ''
        })
        .addCase(allTags.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default participantSlice.reducer;