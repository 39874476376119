import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import ReportService from 'services/ReportService';
import { useDispatch } from 'react-redux';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: []
}


export const getAgeReport = createAsyncThunk('report/getAgeReport', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ReportService.getAgeReport(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const ReportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAgeReport.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAgeReport.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.message = ''
        })
        .addCase(getAgeReport.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default ReportSlice.reducer;