import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const PushNotificationService = {}


PushNotificationService.sendNotification = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/sent-notification`,
		method: 'post',
        data: data,

	})
};

PushNotificationService.getAllProgram = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-program`,
		method: 'get',
        //data: data,

	})
};

PushNotificationService.getAllCaregiver = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-care-giver`,
		method: 'get',
        //data: data,

	})
};

PushNotificationService.getAllInstructor = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-instructor`,
		method: 'get',
        //data: data,

	})
};

PushNotificationService.getAllTags = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-tags`,
		method: 'get',
        //data: data,

	})
};


PushNotificationService.getAllNotification = function (params) {
	let page = params
	let offset = (page-1)*10

	return fetch({
		url: API_BASE_URL+`/v1/admin/get-notification`,
		method: 'get',
         params: {
            'offset': offset
        },

	})
};

export default PushNotificationService;