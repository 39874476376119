import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const ParticipantService = {}



ParticipantService.getParticipant = function (data) {
	let {page, searchParticipant} = data;
    let offset = (page-1)*10;
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-all-participant',
		method: 'get',
        params: {
            'offset' : offset,
			searchParticipant
        }
		
	})
};


ParticipantService.getGuestParticipant = function (params) {

    let offset = (params-1)*10;
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-all-guest-participant',
		method: 'get',
        params: {
            'offset' : offset
        }
		
	})
};

ParticipantService.getParticipantWithId = function (id) {

   
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-participant-details/${id}`,
		method: 'get',
	})
};

ParticipantService.addParticipantTag = function (data) {

	return fetch({
		url: API_BASE_URL+`/v1/admin/add-participant-tag`,
		method: 'post',
		data
	});
};

ParticipantService.allTags = function (data) {

	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-tags`,
		method: 'get',
	});
};


export default ParticipantService;