import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import InstructorService from 'services/InstructorService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    resData: [],
    resError: false,
    resStatus: false,
    singleData:[],
}


export const getInstructor = createAsyncThunk('instructor/getInstructor', async (params, { rejectWithValue }) => {
	
	try {
		const response = await InstructorService.getInstructor(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const deleteInstructor = createAsyncThunk('instructor/deleteInstructor', async (id, { rejectWithValue }) => {
	
	try {
		const response = await InstructorService.deleteInstructor(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const registerInstructor = createAsyncThunk('instructor/registerInstructor', async(data, {rejectWithValue}) => {
    try{
        const response = await InstructorService.registerInstructor(data)
        if(response.status) {
            console.log(response);
            return response.data
        }
    } catch (error) {
        return rejectWithValue(error.message || 'Error')
    }
});

export const editInstructor = createAsyncThunk('instructor/editInstructor', async (data, { rejectWithValue }) => {
	
	try {
		const response = await InstructorService.editInstructor(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getSingleInstructor = createAsyncThunk('instructor/getSingleInstructor', async (id, { rejectWithValue }) => {
	
	try {
		const response = await InstructorService.getSingleInstructor(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const instructorSlice = createSlice({
    name: 'instructor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getInstructor.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.data = action.payload
        })
        .addCase(getInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(deleteInstructor.pending, (state) => {
            state.resStatus= false
            state.loading = true
        })
        .addCase(deleteInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.resStatus= true
           // state.data = action.payload
        })
        .addCase(deleteInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(registerInstructor.pending, (state) => {
            //state.resError= false
            state.loading = true
        })
        .addCase(registerInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload
            state.message = 'Instructor Registered'
        })
        .addCase(registerInstructor.rejected, (state, action) => {
            // state.resError= true
            // state.message = 'Instructor Not Registerd'
            // state.showMessage = true
            state.loading = false
        })
        .addCase(editInstructor.pending, (state) => {
            //state.resError= false
            state.loading = true
        })
        .addCase(editInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload
            state.message = 'Instructor Updated'
        })
        .addCase(editInstructor.rejected, (state, action) => {
            // state.resError= true
            // state.message = 'Instructor Not Registerd'
            // state.showMessage = true
            state.loading = false
        })
        .addCase(getSingleInstructor.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getSingleInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.singleData = action.payload
        })
        .addCase(getSingleInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default instructorSlice.reducer;