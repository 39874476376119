import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const SurveyService = {}


SurveyService.addSurvey = function (data ) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-survey',
		method: 'post',
        
		data: data,
	})
};


SurveyService.getSurvey = function (params) {
	console.log("page",params)
	let page = params
	let offset = (page-1)*10
	console.log("offset",parseInt(offset))

	return fetch({
		url: API_BASE_URL+'/v1/admin/get-survey',
		method: 'get',
		params: {
            'offset': offset
        },

	})
};



export default SurveyService;