import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import ProgramService from 'services/ProgramService';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

export const initialState = {
	loading: false,
    typeCheck: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    session: [],
    survey:[],
    tags: [],
    resData: [],
    singleProgram: [],
    singleProgramSession: [],
    all:[],
    draft:[],
    requested:[],
    ongoing:[],
    upcoming:[],
    participant: [],
    guestParticipant: [],
    programId: '',
    programType: '',
    clonedProgramData : [],
    updateData: false,
    updateParticipantStatus: false,
    instructor:[],
    allPendingParticipant:[],
}

export const addProgram = createAsyncThunk('program/addProgram', async (data, { rejectWithValue }) => {

	try {  
		const response = await ProgramService.addProgram(data)
		if (response.status) {

			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgram = createAsyncThunk('program/getProgram', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgram(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addProgramSession = createAsyncThunk('program/addProgramSession', async (data, { rejectWithValue }) => {
	
	try {  
		const response = await ProgramService.addProgramSession(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramSession = createAsyncThunk('program/getProgramSession', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramSession(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addProgramSurvey = createAsyncThunk('program/addProgramSurvey', async (data, { rejectWithValue }) => {
	
	try {  
		const response = await ProgramService.addProgramSurvey(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramSurvey = createAsyncThunk('program/getProgramSurvey', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramSurvey(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addProgramTag = createAsyncThunk('program/addProgramTag', async (data, { rejectWithValue }) => {
	
	try {  
		const response = await ProgramService.addProgramTag(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllTags = createAsyncThunk('program/getAllTags', async (params, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getAllTags(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramById = createAsyncThunk('program/getProgramById', async (params, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramById(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramParticipant = createAsyncThunk('program/getProgramParticipant', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramParticipant(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramGuestParticipant = createAsyncThunk('program/getProgramGuestParticipant', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramGuestParticipant(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const updateProgramParticipantStatus = createAsyncThunk('program/updateProgramParticipantStatus', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.updateProgramParticipantStatus(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const cloneSession = createAsyncThunk('program/cloneSession', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.cloneSession(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const cloneProgram = createAsyncThunk('program/cloneProgram', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.cloneProgram(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const changeProgramStatus = createAsyncThunk('program/changeProgramStatus', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.changeProgramStatus(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const setProgramId = createAsyncThunk('program/setProgramId', async (data, { rejectWithValue }) => {
	
	try {
		 if(data){
            return data;
         }else{
            return 
         }
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const setProgramType = createAsyncThunk('program/setProgramType', async (data, { rejectWithValue }) => {
	
	try {
		 if(data){
            return data;
         }else{
            return 
         }
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const deleteSession = createAsyncThunk('program/deleteSession', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.deleteSession(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramInstructor = createAsyncThunk('program/getProgramInstructor', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramInstructor(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getProgramCaregiver = createAsyncThunk('program/getProgramCaregiver', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getProgramCaregiver(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addProgramInstructor = createAsyncThunk('program/addProgramInstructor', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.addProgramInstructor(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addProgramCaregiver = createAsyncThunk('program/addProgramCaregiver', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.addProgramCaregiver(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getSessionParticipant = createAsyncThunk('program/getSessionParticipant', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.getSessionParticipant(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const updateSessionParticipantStatus = createAsyncThunk('program/updateSessionParticipantStatus', async (data, { rejectWithValue }) => {
	
	try {
		const response = await ProgramService.updateSessionParticipantStatus(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const removeProgramSurvey = createAsyncThunk('program/removeProgramSurvey', async (data, { rejectWithValue }) => {
	
	try {  
		const response = await ProgramService.removeProgramSurvey(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllPendingParticipant = createAsyncThunk('program/getAllPendingParticipant', async (data, { rejectWithValue }) => {
	
	try {  
		const response = await ProgramService.getAllPendingParticipant(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(addProgram.pending, (state) => {
            state.loading = true
        })
        .addCase(addProgram.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload
            state.message = 'Program Created'
            
        })
        .addCase(addProgram.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgram.pending, (state) => {
            state.loading = true
            state.clonedProgramData = []
        })
        .addCase(getProgram.fulfilled, (state, action) => {
            state.loading = false
            // console.log('payload',action)
            if(action.meta.arg.type === ''){
                state.all= action.payload
             }
             if(action.meta.arg.type === 'draft'){
                state.draft= action.payload
             }
             if(action.meta.arg.type === 'upcoming'){
                state.upcoming= action.payload
             }
             if(action.meta.arg.type === 'requested'){
                state.requested= action.payload
             }
             if(action.meta.arg.type === 'ongoing'){
                state.ongoing = action.payload
             }
             if(action.meta.arg.type === ''){
                state.data = action.payload
             }

           // state.data = action.payload.data
            state.message = ''
            state.resData = []
        })
        .addCase(getProgram.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addProgramSession.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addProgramSession.fulfilled, (state, action) => {
            state.loading = false
           // state.session = action.payload
            state.message = 'Session Added'
        })
        .addCase(addProgramSession.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramSession.pending, (state) => {
            state.loading = true
            state.updateData = false
        })
        .addCase(getProgramSession.fulfilled, (state, action) => {
            state.loading = false
            state.session = action.payload
        })
        .addCase(getProgramSession.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addProgramSurvey.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addProgramSurvey.fulfilled, (state, action) => {
            state.loading = false
           // state.survey = action.payload
            state.message = 'Survey Added'
        })
        .addCase(addProgramSurvey.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramSurvey.pending, (state) => {
            state.loading = true
        })
        .addCase(getProgramSurvey.fulfilled, (state, action) => {
            state.loading = false
            state.survey = action.payload
        })
        .addCase(getProgramSurvey.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addProgramTag.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addProgramTag.fulfilled, (state, action) => {
            state.loading = false
            state.tags = action.payload
            state.message = 'Tags added to program'
        })
        .addCase(addProgramTag.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllTags.pending, (state) => {
            state.loading = true
        })
        .addCase(getAllTags.fulfilled, (state, action) => {
            state.loading = false
            state.tags = action.payload
        })
        .addCase(getAllTags.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramById.pending, (state) => {

            state.loading = true
            state.clonedProgramData= []
            state.message = ''
            state.updateData = false
        })
        .addCase(getProgramById.fulfilled, (state, action) => {
            state.loading = false
            state.singleProgram = action.payload.program_data
            state.singleProgramSession = action.payload.session
        })
        .addCase(getProgramById.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramParticipant.pending, (state) => {
            state.participant = []
            state.loading = true
        })
        .addCase(getProgramParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.updateParticipantStatus = false
            state.participant = action.payload
            
        })
        .addCase(getProgramParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramGuestParticipant.pending, (state) => {
            state.guestParticipant = []
            state.loading = true
        })
        .addCase(getProgramGuestParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.updateParticipantStatus = false
            state.guestParticipant = action.payload
            
        })
        .addCase(getProgramGuestParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(updateProgramParticipantStatus.pending, (state) => {
            state.loading = true
        })
        .addCase(updateProgramParticipantStatus.fulfilled, (state, action) => {
            state.loading = false
            state.updateParticipantStatus = true
           // state.participant = action.payload
        })
        .addCase(updateProgramParticipantStatus.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(cloneSession.pending, (state) => {
            state.loading = true
        })
        .addCase(cloneSession.fulfilled, (state, action) => {
            state.loading = false
            state.updateData = true
           // state.participant = action.payload
        })
        .addCase(cloneSession.rejected, (state, action) => {
            state.message = action.payload
          //  state.showMessage = true
            state.loading = false
        })
        .addCase(cloneProgram.pending, (state) => {
            state.loading = true
        })
        .addCase(cloneProgram.fulfilled, (state, action) => {
            state.loading = false
            state.clonedProgramData = action.payload
        })
        .addCase(cloneProgram.rejected, (state, action) => {
            state.message = action.payload
          //  state.showMessage = true
            state.loading = false
        })
        .addCase(changeProgramStatus.pending, (state) => {
            state.loading = true
        })
        .addCase(changeProgramStatus.fulfilled, (state, action) => {
            state.loading = false
            state.updateData = true
        })
        .addCase(changeProgramStatus.rejected, (state, action) => {
            state.message = action.payload
          //  state.showMessage = true
            state.loading = false
        })
        .addCase(setProgramId.pending, (state) => {
            state.loading = true
        })
        .addCase(setProgramId.fulfilled, (state, action) => {
            state.loading = false
            state.programId = action.payload
        })
        .addCase(setProgramId.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(setProgramType.pending, (state) => {
            state.typeCheck = true
        })
        .addCase(setProgramType.fulfilled, (state, action) => {
            state.typeCheck = false
            state.programType = action.payload
        })
        .addCase(setProgramType.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.typeCheck = false
        })
        .addCase(deleteSession.pending, (state) => {
            state.loading = true
        })
        .addCase(deleteSession.fulfilled, (state, action) => {
            state.loading = false
            state.updateData = true
           // state.clonedProgramData = action.payload
        })
        .addCase(deleteSession.rejected, (state, action) => {
            state.message = action.payload
          //  state.showMessage = true
            state.loading = false
        })
        .addCase(getProgramInstructor.pending, (state) => {
            state.instructor = []
            state.loading = true
        })
        .addCase(getProgramInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.instructor = action.payload 
        })
        .addCase(getProgramInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        
        .addCase(getProgramCaregiver.pending, (state) => {
            state.caregiver = []
            state.loading = true
        })
        .addCase(getProgramCaregiver.fulfilled, (state, action) => {
            state.loading = false
            state.caregiver = action.payload 
        })
        .addCase(getProgramCaregiver.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })

        .addCase(addProgramInstructor.pending, (state) => {
            state.instructor = []
            state.loading = true
        })
        .addCase(addProgramInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload 
        })
        .addCase(addProgramInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addProgramCaregiver.pending, (state) => {
            state.caregiver = []
            state.loading = true
        })
        .addCase(addProgramCaregiver.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload 
        })
        .addCase(addProgramCaregiver.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getSessionParticipant.pending, (state) => {
            state.participant = []
            state.loading = true
        })
        .addCase(getSessionParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.updateParticipantStatus = false
            state.participant = action.payload
            
        })
        .addCase(getSessionParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(updateSessionParticipantStatus.pending, (state) => {
            state.loading = true
        })
        .addCase(updateSessionParticipantStatus.fulfilled, (state, action) => {
            state.loading = false
            state.updateParticipantStatus = true
           // state.participant = action.payload
        })
        .addCase(updateSessionParticipantStatus.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(removeProgramSurvey.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(removeProgramSurvey.fulfilled, (state, action) => {
            state.loading = false
           // state.survey = action.payload
           // state.message = 'Survey Removed'
           state.updateData = true
        })
        .addCase(removeProgramSurvey.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllPendingParticipant.pending, (state) => {
            state.loading = true
            state.message = ''
            state.updateParticipantStatus= false
        })
        .addCase(getAllPendingParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.allPendingParticipant = action.payload
           // state.survey = action.payload
           // state.message = 'Survey Removed'
          // state.updateData = true
        })
        .addCase(getAllPendingParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })

        
    }
});



export default programSlice.reducer;