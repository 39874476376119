import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import PushNotificationService from 'services/PushNotificationService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    allProgram: [],
    allTags: [],
    allCaregiver: [],
    allInstructor: [],
    allNotification:[],
}


export const sendNotification = createAsyncThunk('PushNotification/sendNotification', async (data, { rejectWithValue }) => {
	
	try {
		const response = await PushNotificationService.sendNotification(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllProgram = createAsyncThunk('PushNotification/getAllProgram', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await PushNotificationService.getAllProgram(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllCaregiver = createAsyncThunk('PushNotification/getAllCaregiver', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await PushNotificationService.getAllCaregiver(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllInstructor = createAsyncThunk('PushNotification/getAllInstructor', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await PushNotificationService.getAllInstructor(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllTags = createAsyncThunk('PushNotification/getAllTags', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await PushNotificationService.getAllTags(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllNotification = createAsyncThunk('PushNotification/getAllNotification', async (data, { rejectWithValue }) => {
	    
	try {
		const response = await PushNotificationService.getAllNotification(data)
		if (response.status) {
            
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const pushNotificationSlice = createSlice({
    name: 'PushNotification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(sendNotification.pending, (state) => {
            state.loading = true
            state.message= ''
        })
        .addCase(sendNotification.fulfilled, (state, action) => {
            state.loading = false
           // state.data.unshift(action.payload);
            state.message = "Notification Sent"

        })
        .addCase(sendNotification.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllProgram.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllProgram.fulfilled, (state, action) => {
            state.loading = false
            state.allProgram = action.payload
            state.message= ''
        })
        .addCase(getAllProgram.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllCaregiver.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllCaregiver.fulfilled, (state, action) => {
            state.loading = false
            state.allCaregiver = action.payload
            state.message= ''
        })
        .addCase(getAllCaregiver.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllInstructor.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllInstructor.fulfilled, (state, action) => {
            state.loading = false
            state.allInstructor = action.payload
            state.message= ''
        })
        .addCase(getAllInstructor.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllTags.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllTags.fulfilled, (state, action) => {
            state.loading = false
            state.allTags = action.payload
            state.message= ''
        })
        .addCase(getAllTags.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllNotification.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllNotification.fulfilled, (state, action) => {
            state.loading = false
            state.allNotification = action.payload
            state.message= ''
        })
        .addCase(getAllNotification.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default pushNotificationSlice.reducer;