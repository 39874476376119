import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import FaqService from 'services/FaqService';
import { useDispatch } from 'react-redux';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: []
}


export const addFaq = createAsyncThunk('tag/addFaq', async (data, { rejectWithValue }) => {
	
	try {
		const response = await FaqService.addFaq(data)
		if (response.status) {
			console.log(response);
            
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const getAllFaq = createAsyncThunk('tag/getAllFaq', async (data, { rejectWithValue }) => {
	
	try {
		const response = await FaqService.getAllFaq(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const FaqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(addFaq.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addFaq.fulfilled, (state, action) => {
            state.loading = false
           // state.data.unshift(action.payload);
            state.message = "FAQ Added"
        })
        .addCase(addFaq.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllFaq.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAllFaq.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.message = ''
        })
        .addCase(getAllFaq.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
    }
});



export default FaqSlice.reducer;