import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import programCategory from './slices/programCategorySlice';
import programSubCategory from './slices/programSubCategorySlice';
import program from './slices/programSlice';
import survey from './slices/surveySlice';
import caregiver from './slices/caregiverSlice';
import instructor from './slices/instructorSlice';
import questionAnswer from './slices/questionAnswerSlice';
import pushNotification from './slices/pushNotificationSlice';
import tag from './slices/tagSlice';
import participant from './slices/participantSlice';
import faq from './slices/faqSlice';
import report from './slices/reportSlice';


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        programCategory,
        programSubCategory,
        program,
        survey,
        caregiver,
        instructor,
        questionAnswer,
        pushNotification,
        tag,
        participant,
        faq,
        report,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
