import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const ProgramCategoryService = {}


ProgramCategoryService.addCategory = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-category',
		method: 'post',
		data: data,
	})
};

ProgramCategoryService.addSubCategory = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-sub-category',
		method: 'post',
		data: data
	})
};

ProgramCategoryService.getCategory = function (params) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-category',
		method: 'get',
		
	})
};


ProgramCategoryService.getSubCategory = function (params) {
	return fetch({
		url:API_BASE_URL+'/v1/admin/get-sub-category',
		method: 'get',
		
	})
};


export default ProgramCategoryService;