const dev = {
	API_ENDPOINT_URL: 'https://app.liquid-therapy.ie/api'
	//  API_ENDPOINT_URL: 'https://liquidapi.thecoderspottest.com/api'
	// API_ENDPOINT_URL: 'http://localhost:3005/api'

};

const prod = {
	API_ENDPOINT_URL: 'https://app.liquid-therapy.ie/api'
	// API_ENDPOINT_URL: 'http://localhost:3005/api'
	//  API_ENDPOINT_URL: 'https://liquidapi.thecoderspottest.com/api'
	// API_ENDPOINT_URL: 'http://localhost:3005/api'
};


const test = {

	API_ENDPOINT_URL: 'https://app.liquid-therapy.ie/api'
	//  API_ENDPOINT_URL: 'https://liquidapi.thecoderspottest.com/api'
	// API_ENDPOINT_URL: 'http://localhost:3005/api'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
