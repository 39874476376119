import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/v1/admin/register',
		method: 'post',
		data: data
	})
}

export default AuthService;