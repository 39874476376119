import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const ProgramService = {}


ProgramService.addProgram = function (data ) {
	
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-program',
		method: 'post',
        headers: {
            'Content-Type': "multipart/form-data",
        },
		data: data,
	})
};


ProgramService.getProgram = function (params) {
	 let {page, type, searchProgram} = params
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-program',
		method: 'get',
		params: {
            'offset': offset,
			'type': type,
			searchProgram,
        },

	})
};

ProgramService.addProgramSession = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-program-session',
		method: 'post',
        headers: {
            'Content-Type': "multipart/form-data",
        },
		data: data,
	})
};

ProgramService.getProgramSession = function (data) {
    let{id, page} = data
    let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-session/${id}`,
		method: 'get',
		params: {
            'offset': offset,
        }
	})
};

ProgramService.addProgramTag = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-program-tag',
		method: 'post',
		data: data,
	})
};

ProgramService.addProgramSurvey = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/add-program-survey',
		method: 'post',
		data: data,
	})
};

ProgramService.getProgramSurvey = function ( data ) {
	 let {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-survey/${id}`,
		method: 'post',
		
        params: {
            'offset': offset
        }
	})
};

ProgramService.getAllTags = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-all-tags',
		method: 'get',
		
	})
};

ProgramService.getProgramById = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-by-id/${id}`,
		method: 'get',
		
	})
};

ProgramService.getProgramParticipant = function (data) {
	const {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-participants/${id}`,
		method: 'get',
		params: {
			'offset': offset
		}
		
	})
};

ProgramService.getProgramGuestParticipant = function (data) {
	const {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-guest-participants/${id}`,
		method: 'get',
		params: {
			'offset': offset
		}
		
	})
};

ProgramService.updateProgramParticipantStatus = function (data) {
	const {id, status} = data;
	//let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/update-participant-status/${id}`,
		method: 'post',
		data: status
		// params: {
		// 	'offset': offset
		// }
		
	})
};

ProgramService.cloneSession = function (data) {
	const {id} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/clone-program-session/${id}`,
		method: 'post',
		//data: status
		// params: {
		// 	'offset': offset
		// }
		
	});
};

ProgramService.cloneProgram = function (data) {
	const {id} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/clone-program/${id}`,
		method: 'post',
		//data: status
		// params: {
		// 	'offset': offset
		// }
		
	});
};

ProgramService.changeProgramStatus = function (data) {
	const {id} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/change-program-status/${id}`,
		method: 'post',
		//data: status
		// params: {
		// 	'offset': offset
		// }
		
	});
};

ProgramService.deleteSession = function (data) {
	
	return fetch({
		url: API_BASE_URL+`/v1/admin/delete-program-session`,
		method: 'post',
		data: data
		// params: {
		// 	'offset': offset
		// }
		
	});
};

ProgramService.getProgramInstructor = function (data) {
	const {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-instructor/${id}`,
		method: 'get',
		params: {
			'offset': offset
		}
		
	})
};

ProgramService.getProgramCaregiver = function (data) {
	const {id, page} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-program-caregiver/${id}`,
		method: 'get',
		params: {
			'offset': offset
		}
		
	})
};

ProgramService.addProgramInstructor = function (data) {
	
	return fetch({
		url: API_BASE_URL+`/v1/admin/add-program-instructor`,
		method: 'post',
		data
		
	});
};

ProgramService.addProgramCaregiver = function (data) {
	
	return fetch({
		url: API_BASE_URL+`/v1/admin/add-program-caregiver`,
		method: 'post',
		data
		
	});
};

ProgramService.getSessionParticipant = function (data) {
	const {id, page} = data;
	//console.log('service', id)
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-session-participants/${id}`,
		method: 'get',
		params: {
			'offset': offset
		}
		
	})
};

ProgramService.updateSessionParticipantStatus = function (data) {
	const {id, status} = data;
	//let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/update-status-participants/${id}`,
		method: 'post',
		data: status
		// params: {
		// 	'offset': offset
		// }
		
	})
};

ProgramService.removeProgramSurvey = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/remove-program-survey',
		method: 'post',
		data: data,
	})
};

ProgramService.getAllPendingParticipant = function (data) {
	const {id, page, searchParticipant} = data;
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-unAccepted-participants`,
		method: 'get',
		params: {
			'offset': offset,
			searchParticipant
		}
		
	})
};


export default ProgramService;