import fetch from 'auth/FetchInterceptor';
import {API_BASE_URL} from '../configs/AppConfig';

const ReportService = {};


ReportService.getAgeReport = function (params) {
    // let page = params
	// let offset = (page-1)*10
    return fetch({
        url: API_BASE_URL+'/v1/admin/get-participant-report',
		method: 'get',
		//data: data,
        // params: {
        //     'offset': offset
        // },
    })
};

export default ReportService;

